<template>
  <v-row class="mt-2">
    <v-col
      cols="12"
      md="12"
    >
      <h3>
        {{ $t('lbl.payMedio') }}
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="ml-5"
              fab
              dark
              x-small
              color="primary"
              v-bind="attrs"
              v-on="on"
              @click="addCtasBancarias()"
            >
              <v-icon small>
                {{ icons.mdiPlus }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('btn.insert') }}</span>
        </v-tooltip>
      </h3>
    </v-col>
    <v-col
      cols="12"
      md="12"
    >
      <PayMedio
        v-for="(payMedio, indP) in ctasBancarias"
        :key="indP"
        :pos="indP"
        :index="index"
        :pay-medio="payMedio"
        :pay-medios="ctasBancarias"
        :pay-ments="payMents"
        :monedas="monedas"
        :countries="countries"
        @setIndex="setIndex"
      />
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiPlus,
  mdiArrowLeft,
  mdiSquareEditOutline,
  mdiDeleteOutline,
  mdiUpdate,
  mdiCalendarRange,
  mdiCancel,
  mdiDomain,
  mdiMenuDown,
  mdiMenuRight,
  mdiTrashCan,
} from '@mdi/js'
import PayMedio from './PayMedio.vue'

export default {
  components: {
    PayMedio,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    payMents: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    monedas: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    countries: {
      type: Array,
    },
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      typesMethodPays: [],
      showMonto: false,
      showTime: false,
      times: [],
      monto: null,
      time: null,
      showDeposit: false,
      showCredit: false,
      showOperaCont: false,
      showCompPay: false,
      index: -1,
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiUpdate,
        mdiCalendarRange,
        mdiCancel,
        mdiDomain,
        mdiMenuDown,
        mdiMenuRight,
        mdiTrashCan,
      },
      loading: false,
      permisos: sessionStorage.getItem('permisos_auth'),
    }
  },
  computed: {
    ...mapState({
      ctasBancarias: state => state.app.ctasBancarias,
    }),
  },
  methods: {
    ...mapMutations(['addCtasBancarias']),
    setIndex(pos) {
      this.index = pos
    },
  },
}
</script>
<style>
/**PARA OCULTAR LA FECHA DE HOY EN EL COMPONENTE DE HORA */
.vc-date-time .vc-date .vc-weekday,
.vc-date-time .vc-date .vc-month,
.vc-date-time .vc-date .vc-day,
.vc-date-time .vc-date .vc-year {
  display: none;
}
::v-deep .my-input .v-label,
::v-deep .my-input input {
  font-size: 12px !important;
  line-height: 1;
  min-height: 8px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
::v-deep .v-text-field__details {
  padding: 0 0 0 0;
}
</style>
