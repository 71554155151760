<template>
  <v-row class="mt-2">
    <v-col
      cols="12"
      md="6"
    >
      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          <strong>{{ $t('lbl.systemCode') }}</strong>
        </v-col>
        <v-col
          cols="12"
          md="12"
        >
          <v-row no-gutters>
            <v-col
              cols="12"
              md="6"
            >
              <p class="mb-1 mt-2">
                <b>{{ $t('lbl.systemCodePrefijo') }}</b>
              </p>
              <v-text-field
                v-model="model.siglas"
                class="pb-5"
                :label="$t('lbl.siglas')"
                outlined
                dense
                hide-details
                placeholder="PPP"
                disabled
                @input="val => (model.siglas = model.siglas.toUpperCase())"
              >
                <!--
                  :append-outer-icon="icons.mdiInformation"
                  <template slot="append-outer">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ icons.mdiInformation }}
                      </v-icon>
                    </template>
                    <span>
                      <div class="d-block">
                        <div class="d-flex justify-start align-center">
                          <p class="mb-1 mt-2">
                            <b>{{ $t('lbl.systemCodePrefijo') }}</b>
                          </p>
                        </div>
                      </div>
                    </span>
                  </v-tooltip>
                </template>-->
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <strong class="mr-5">{{ $t('menu.products') }}</strong>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ icons.mdiInformation }}
                  </v-icon>
                </template>
                <span>
                  <div class="d-block">
                    <div class="d-flex justify-start align-center">
                      <p class="mb-1 mt-2">
                        <b>{{ $t('lbl.systemCodeProduct') }}</b>
                      </p>
                    </div>
                  </div>
                </span>
              </v-tooltip>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="model.siglas_flights"
                class="pb-5"
                :label="$t('menu.flights')"
                outlined
                dense
                hide-details
                placeholder="VV"
                disabled
                @input="val => (model.siglas_flights = model.siglas_flights.toUpperCase())"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="model.siglas_cars"
                class="pb-5"
                :label="$t('menu.cars')"
                outlined
                dense
                hide-details
                placeholder="AA"
                disabled
                @input="val => (model.siglas_cars = model.siglas_cars.toUpperCase())"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="model.siglas_hotel"
                class="pb-5"
                :label="$t('menu.hotels')"
                outlined
                dense
                hide-details
                placeholder="HH"
                disabled
                @input="val => (model.siglas_hotel = model.siglas_hotel.toUpperCase())"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col
              cols="12"
              md="12"
            >
              <strong>Ej:</strong>
              <code><strong>{{ code }}</strong></code>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>

    <v-col
      cols="12"
      md="6"
    >
      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          <strong class="mr-5">{{ $t('lbl.emailsReserve') }}</strong>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="primary"
                v-bind="attrs"
                v-on="on"
              >
                {{ icons.mdiInformation }}
              </v-icon>
            </template>
            <span>
              <div class="d-block">
                <div class="d-flex justify-start align-center">
                  <p class="mb-1 mt-2">
                    <b>{{ $t('lbl.infoEmailsReserve') }}</b>
                  </p>
                </div>
              </div>
            </span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mt-1 mx-2"
                fab
                dark
                x-small
                color="primary"
                v-bind="attrs"
                v-on="on"
                @click="addEmailAdminReserve"
              >
                <v-icon small>
                  {{ icons.mdiPlus }}
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('btn.insert') }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row v-if="emailsReserve.length > 0">
        <v-col
          cols="12"
          md="12"
        >
          <Email
            v-for="(email, indEma) in emailsReserve"
            :key="indEma"
            :email="email"
            :pos="indEma"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiPlus,
  mdiArrowLeft,
  mdiSquareEditOutline,
  mdiDeleteOutline,
  mdiUpdate,
  mdiCalendarRange,
  mdiCancel,
  mdiDomain,
  mdiMenuDown,
  mdiMenuRight,
  mdiTrashCan,
  mdiInformation,
} from '@mdi/js'

import Email from './Email.vue'

export default {
  components: {
    Email,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    model: {
      type: Object,
    },
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiUpdate,
        mdiCalendarRange,
        mdiCancel,
        mdiDomain,
        mdiMenuDown,
        mdiMenuRight,
        mdiTrashCan,
        mdiInformation,
      },
      loading: false,
      permisos: sessionStorage.getItem('permisos_auth'),
    }
  },
  computed: {
    ...mapState({
      dataBancaria: state => state.app.dataBancaria,
      emailsReserve: state => state.app.emailsReserve,
    }),
    code() {
      let sig = 'PPP'
      if (this.model.siglas) {
        sig = this.model.siglas
      }
      const dat = this.$moment(new Date()).format('DDMMYY')
      let sigV = 'VV'
      if (this.model.siglas_flights) {
        sigV = this.model.siglas_flights
      }

      return `${sig}-${dat}${sigV}${Math.random()
        .toString(36)
        .slice(-3)
        .toUpperCase()}`
    },
  },

  methods: {
    ...mapMutations(['addEmailAdminReserve', 'updateEmailAdminReserve']),
  },
}
</script>
<style scoped>
::v-deep .my-input .v-label,
::v-deep .my-input input {
  font-size: 12px !important;
  line-height: 1;
  min-height: 8px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
::v-deep .v-text-field__details {
  padding: 0 0 0 0;
}
</style>
