<template>
  <span>
    <v-row>
      <v-col
        cols="12"
        md="10"
      >
        <span v-if="!showRow || index !== pos">
          <span v-if="payMedio.payments === 'banco'">
            <span v-if="payMedio.moneda">
              {{ `${payMedio.moneda}-` }}
            </span>
            <span v-if="payMedio.name_banco">
              {{ payMedio.name_banco }}
            </span>
            <span v-if="payMedio.principal">
              <b>{{ ` (${$t('lbl.principal')})` }}</b>
            </span>
          </span>
          <span v-if="payMedio.payments === 'zelle'">
            <span v-if="payMedio.dataZelle.email">
              {{ `Email: ${payMedio.dataZelle.email}` }}
            </span>
            <span v-if="payMedio.dataZelle.phone">
              {{ `Cell: ${payMedio.dataZelle.phone}` }}
            </span>
            <span v-if="payMedio.principal">
              <b>{{ ` (${$t('lbl.principal')})` }}</b>
            </span>
          </span>
          <span v-if="payMedio.payments === 'tarjeta-debito-credito'">
            <span v-if="payMedio.dataCards">
              <span v-if="payMedio.dataCards.number">
                {{
                  `Tarjeta (${typeCards}): ${'X'.repeat(
                    payMedio.dataCards.number.length - 4,
                  )}${payMedio.dataCards.number.slice(-4)}`
                }}
              </span>
              <v-avatar tile>
                <Card
                  v-if="!typeCard"
                  :height="40"
                  :width="40"
                />
                <Mastercard
                  v-else-if="typeCard === 'mastercard'"
                  :height="40"
                  :width="40"
                />
                <AmericanExpress
                  v-else-if="typeCard === 'american-express'"
                  :height="40"
                  :width="40"
                />
                <Dankort
                  v-else-if="typeCard === 'dankort'"
                  :height="40"
                  :width="40"
                />
                <Diners
                  v-else-if="typeCard === 'diners'"
                  :height="40"
                  :width="40"
                />
                <Discover
                  v-else-if="typeCard === 'discover'"
                  :height="40"
                  :width="40"
                />
                <Jcb
                  v-else-if="typeCard === 'jcb'"
                  :height="40"
                  :width="40"
                />
                <Visa
                  v-else-if="typeCard === 'visa'"
                  :height="40"
                  :width="40"
                />
                <VisaElectron
                  v-else-if="typeCard === 'visa-electron'"
                  :height="40"
                  :width="40"
                />
                <Maestro
                  v-else-if="typeCard === 'maestro'"
                  :height="40"
                  :width="40"
                />
                <UnionPay
                  v-else-if="typeCard === 'unionpay'"
                  :height="40"
                  :width="40"
                />
              </v-avatar>

              <span v-if="payMedio.dataCards.principal">
                <b>{{ ` (${$t('lbl.principal')})` }}</b>
              </span>
            </span>
          </span>
        </span>

        <v-radio-group
          v-if="showRow && index === pos"
          v-model="payMedio.payments_id"
          class="mt-0 pt-0"
          row
        >
          <v-col
            cols="12"
            md="3"
          ></v-col>
          <v-col
            v-for="(method, indEx) in payMentsLocal"
            :key="indEx"
            cols="12"
            md="2"
          >
            <!--:disabled="method.slug !== 'banco'"-->
            <v-card color="text-center">
              <v-card-text>
                <v-row>
                  <v-col
                    cols="1"
                    class="justify-center align-center mt-2"
                  >
                    <v-radio
                      label=""
                      :value="method.id"
                      :disabled="method.disabled"
                      @click="changePayMedio(method)"
                    ></v-radio>
                  </v-col>
                  <v-col cols="8">
                    <v-avatar
                      v-if="method.slug === 'zelle'"
                      tile
                    >
                      <Zelle
                        :color="
                          method.disabled ? $vuetify.theme.themes.light.secondary : $vuetify.theme.themes.light.primary
                        "
                      />
                    </v-avatar>
                    <v-icon
                      v-else
                      size="3rem"
                      color="primary"
                      :disabled="method.disabled"
                    >
                      {{ method.icon }}
                    </v-icon>
                  </v-col>
                </v-row>
                <h2 class="mt-4">
                  {{ method.name }}
                </h2>
              </v-card-text>
            </v-card>
          </v-col>
        </v-radio-group>
      </v-col>
      <v-col
        cols="12"
        md="1"
      >
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              fab
              outlined
              v-bind="attrs"
              v-on="on"
              @click="showItem"
            >
              {{ showRow && index === pos ? icons.mdiMenuDown : icons.mdiMenuRight }}
            </v-icon>
          </template>
          <span>{{ showRow && index === pos ? $t('lbl.hidden') : $t('lbl.show') }}</span>
        </v-tooltip>
      </v-col>
    </v-row>

    <v-row v-if="showRow && index === pos && payMedio.payments === 'banco'">
      <v-col
        cols="12"
        md="5"
      >
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <v-text-field
              v-model="payMedio.name_fiscal_facturation"
              :label="$t('register.nameFact')"
              outlined
              dense
              hide-details="auto"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            md="4"
          >
            <v-select
              v-model="payMedio.moneda"
              :items="monedas"
              :label="$t('register.moneda')"
              outlined
              dense
              item-text="code"
              item-value="code"
              hide-details="auto"
            ></v-select>
          </v-col>
          <v-col
            cols="12"
            md="8"
          >
            <v-text-field
              v-model="payMedio.no_cuenta_bancaria"
              :label="$t('register.noBanco')"
              outlined
              dense
              hide-details="auto"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="payMedio.swift"
              :label="$t('register.codeSWIFT')"
              outlined
              dense
              hide-details="auto"
              :rules="swiftRules"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="8"
          >
            <v-text-field
              v-model="payMedio.iban"
              label="IBAN, IRC o CLABE"
              outlined
              dense
              hide-details="auto"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            md="8"
          >
            <v-row v-if="payMedio.principal">
              <v-col
                cols="12"
                md="12"
              >
                <b>{{ $t('lbl.changeCurrency') }}</b>
                <br />
                <v-radio-group
                  v-model="payMedio.update_utc"
                  class="mt-0 pt-0"
                  row
                >
                  <v-radio
                    label="Automático"
                    value="automatic"
                  ></v-radio>
                  <v-radio
                    label="Manual"
                    value="manual"
                    @click="setCountry"
                  ></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-col>

          <v-col
            cols="12"
            md="4"
          >
            <!-- :disabled="disabledRadio" -->
            <v-switch
              v-model="payMedio.principal"
              :label="$t('lbl.principal')"
              hide-details
              class="mt-0"
              :disabled="disablePrincipal"
            ></v-switch>
          </v-col>

          <v-col
            cols="12"
            md="12"
          >
            <span
              v-if="payMedio.update_utc === 'automatic'"
              style="font-size: 12px;color: red"
            >{{
              $t('lbl.typeAuto')
            }}</span>
          </v-col>
        </v-row>
      </v-col>

      <v-col
        cols="12"
        md="1"
      >
        <v-tooltip
          v-if="!payMedio.principal"
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              dark
              x-small
              color="error"
              v-bind="attrs"
              v-on="on"
              @click="deleteCtasBancarias(pos)"
            >
              <v-icon small>
                {{ icons.mdiTrashCan }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('btn.delete') }}</span>
        </v-tooltip>
      </v-col>

      <v-col
        cols="12"
        md="6"
      >
        <v-row>
          <v-col
            cols="12"
            md="7"
          >
            <v-text-field
              v-model="payMedio.name_banco"
              :label="$t('register.nameBanco')"
              outlined
              dense
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="payMedio.code_sucursal"
              :label="$t('register.codeSucursal')"
              outlined
              dense
              hide-details="auto"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            md="12"
          >
            <AddressBancaria
              v-for="(address, indA) in payMedio.address"
              :key="indA"
              :address="address"
              :pos-medio="pos"
              :pos="indA"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col
            cols="12"
            md="3"
          >
            <v-text-field
              v-model="payMedio.code_postal"
              :label="$t('register.codePostal1')"
              outlined
              dense
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model="payMedio.localidad"
              :label="$t('register.locality')"
              outlined
              dense
              hide-details="auto"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-select
              v-model="payMedio.country"
              :items="countries"
              :label="$t('register.country')"
              item-text="name"
              item-value="name"
              outlined
              dense
              hide-details="auto"
              @change="setCountry"
            ></v-select>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row v-if="showRow && index === pos && payMedio.principal && payMedio.update_utc === 'manual'">
      <v-col
        cols="12"
        md="3"
      >
        <v-select
          v-model="payMedio.utc"
          :items="timeZones"
          :label="$t('lbl.timeUTC')"
          outlined
          dense
          hide-details="auto"
        ></v-select>
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <vc-date-picker
          v-model="payMedio.time_utc_public"
          outlined
          dense
          mode="time"
          :model-config="modelTimeConfig"
          is24hr
        >
          <template v-slot="{ inputValue, inputEvents }">
            <v-text-field
              :label="$t('lbl.timeUTCPublic')"
              outlined
              dense
              :value="inputValue"
              v-on="inputEvents"
            >
            </v-text-field>
          </template>
        </vc-date-picker>
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <vc-date-picker
          v-model="payMedio.time_utc_check"
          outlined
          dense
          mode="time"
          :model-config="modelTimeConfig"
          is24hr
        >
          <template v-slot="{ inputValue, inputEvents }">
            <v-text-field
              :label="$t('lbl.timeUTCCheck')"
              outlined
              dense
              :value="inputValue"
              v-on="inputEvents"
            >
            </v-text-field>
          </template>
        </vc-date-picker>
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <vc-date-picker
          v-model="payMedio.time_utc_active"
          outlined
          dense
          mode="time"
          :model-config="modelTimeConfig"
          is24hr
        >
          <template v-slot="{ inputValue, inputEvents }">
            <v-text-field
              :label="$t('lbl.timeUTCActive')"
              outlined
              dense
              :value="inputValue"
              v-on="inputEvents"
            >
            </v-text-field>
          </template>
        </vc-date-picker>
      </v-col>
    </v-row>

    <v-row v-if="showRow && index === pos && payMedio.payments === 'zelle'">
      <v-col
        cols="12"
        md="4"
      >
        <v-text-field
          v-model="payMedio.dataZelle.email"
          :label="$t('lbl.email')"
          outlined
          dense
          hide-details="auto"
        ></v-text-field>
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-text-field
          v-model="payMedio.dataZelle.phone"
          :label="$t('lbl.phoneUSA')"
          outlined
          dense
          hide-details="auto"
        ></v-text-field>
      </v-col>

      <v-col
        cols="12"
        md="2"
      >
        <!-- :disabled="disabledRadio" -->
        <v-switch
          v-model="payMedio.principal"
          :label="$t('lbl.principal')"
          hide-details
          class="mt-0"
          :disabled="disablePrincipal"
        ></v-switch>
      </v-col>
      <v-col
        cols="12"
        md="1"
      >
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              dark
              x-small
              color="error"
              v-bind="attrs"
              v-on="on"
              @click="deleteCtasBancarias(pos)"
            >
              <v-icon small>
                {{ icons.mdiTrashCan }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('btn.delete') }}</span>
        </v-tooltip>
      </v-col>
    </v-row>

    <v-row v-if="showRow && index === pos && payMedio.payments === 'tarjeta-debito-credito'">
      <v-col
        cols="12"
        md="10"
      >
        <Cards
          v-if="payMedio.is_new"
          :card="payMedio.dataCards"
        />
        <CardsEdit
          v-else
          :pay-medio="payMedio"
          :card="payMedio.dataCards"
        />
      </v-col>
      <v-col
        cols="12"
        md="1"
      >
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              dark
              x-small
              color="error"
              v-bind="attrs"
              v-on="on"
              @click="deleteCtasBancarias(pos)"
            >
              <v-icon small>
                {{ icons.mdiTrashCan }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ $t('btn.delete') }}</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-divider class="mt-2 mb-2"></v-divider>
  </span>
</template>

<script>
// eslint-disable-next-line import/no-unresolved
import Zelle from '@/views/utils/images/zelle.vue'
import { mapMutations } from 'vuex'
import {
  mdiPlus,
  mdiArrowLeft,
  mdiSquareEditOutline,
  mdiDeleteOutline,
  mdiUpdate,
  mdiCalendarRange,
  mdiCancel,
  mdiDomain,
  mdiMenuDown,
  mdiMenuRight,
  mdiTrashCan,
} from '@mdi/js'
// eslint-disable-next-line import/no-unresolved
import Visa from '@/views/utils/cards/visa.vue'
// eslint-disable-next-line import/no-unresolved
import Mastercard from '@/views/utils/cards/mastercard.vue'
// eslint-disable-next-line import/no-unresolved
import AmericanExpress from '@/views/utils/cards/americanexpress.vue'
// eslint-disable-next-line import/no-unresolved
import Dankort from '@/views/utils/cards/dankort.vue'
// eslint-disable-next-line import/no-unresolved
import Diners from '@/views/utils/cards/diners.vue'
// eslint-disable-next-line import/no-unresolved
import Discover from '@/views/utils/cards/discover.vue'
// eslint-disable-next-line import/no-unresolved
import Jcb from '@/views/utils/cards/jcb.vue'
// eslint-disable-next-line import/no-unresolved
import VisaElectron from '@/views/utils/cards/visaelectron.vue'
// eslint-disable-next-line import/no-unresolved
import Maestro from '@/views/utils/cards/maestro.vue'
// eslint-disable-next-line import/no-unresolved
import UnionPay from '@/views/utils/cards/unionpay.vue'
// eslint-disable-next-line import/no-unresolved
import Card from '@/views/utils/cards/card.vue'
import Cards from './Cards.vue'
import CardsEdit from './CardsEdit.vue'
import AddressBancaria from './AddressBancaria.vue'

export default {
  components: {
    AddressBancaria,
    Zelle,
    Cards,
    CardsEdit,
    // eslint-disable-next-line vue/no-unused-components
    Visa,
    // eslint-disable-next-line vue/no-unused-components
    Mastercard,
    // eslint-disable-next-line vue/no-unused-components
    AmericanExpress,
    // eslint-disable-next-line vue/no-unused-components
    Dankort,
    // eslint-disable-next-line vue/no-unused-components
    Diners,
    // eslint-disable-next-line vue/no-unused-components
    Discover,
    // eslint-disable-next-line vue/no-unused-components
    Jcb,
    // eslint-disable-next-line vue/no-unused-components
    VisaElectron,
    // eslint-disable-next-line vue/no-unused-components
    Maestro,
    // eslint-disable-next-line vue/no-unused-components
    UnionPay,
    // eslint-disable-next-line vue/no-unused-components
    Card,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    payMedio: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop
    payMedios: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    payMents: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    monedas: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    countries: {
      type: Array,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    pos: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    index: {
      type: Number,
    },
  },
  data() {
    return {
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      isLoading: true,
      typesMethodPays: [],
      showMonto: false,
      showTime: false,
      times: [],
      monto: null,
      time: null,
      showDeposit: false,
      showCredit: false,
      showOperaCont: false,
      showCompPay: false,
      showRow: false,
      icons: {
        mdiPlus,
        mdiArrowLeft,
        mdiSquareEditOutline,
        mdiDeleteOutline,
        mdiUpdate,
        mdiCalendarRange,
        mdiCancel,
        mdiDomain,
        mdiMenuDown,
        mdiMenuRight,
        mdiTrashCan,
      },
      loading: false,
      permisos: sessionStorage.getItem('permisos_auth'),
      swiftRules: [
        // v => !!v || 'Campo requerido',

        // v => v.length <= 8 || 'Swift no válido',
        v => /[0-9a-zA-Z]{8}/.test(v) || 'Swift no válido',
      ],
      timeZones: [],
      modelTimeConfig: {
        type: 'string',
        mask: 'HH:mm',
      },
      payMentsLocal: [],
      typeCard: null,
    }
  },
  computed: {
    disabledRadio() {
      let tienePrincipal = false
      this.payMedios.forEach(element => {
        if (element.principal) {
          tienePrincipal = true
        }
      })

      let disabled = true
      if (tienePrincipal) {
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < this.payMedios.length; index++) {
          const element = this.payMedios[index]

          if (element.principal && element.id === this.payMedio.id && this.pos === index) {
            disabled = false
          }
        }
      } else {
        disabled = false
      }

      return disabled
    },
    disablePrincipal() {
      let disabled = false

      if (this.payMedio.payments === 'banco') {
        if (
          !this.payMedio.name_fiscal_facturation
          || !this.payMedio.no_cuenta_bancaria
          || !this.payMedio.moneda
          || !this.payMedio.iban
          || !this.payMedio.swift
          || !this.payMedio.name_banco
          || this.payMedio.address.length === 0
          || !this.payMedio.code_postal
          || !this.payMedio.localidad
          || !this.payMedio.country
        ) {
          disabled = true
        }
      } else if (this.payMedio.payments === 'zelle') {
        if (!this.payMedio.dataZelle.email && !this.payMedio.dataZelle.phone) {
          disabled = true
        }
      }

      return disabled
    },
    typeCards() {
      let type = ''

      // visa
      let re = new RegExp('^4')
      if (this.payMedio.dataCards.number.match(re) != null) {
        type = 'Visa'
      }

      // Mastercard
      // Updated for Mastercard 2017 BINs expansion
      if (
        /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(
          this.payMedio.dataCards.number,
        )
      ) {
        type = 'Mastercard'
      }

      // American express
      re = new RegExp('^3[47]')
      if (this.payMedio.dataCards.number.match(re) != null) {
        type = 'American Express'
      }

      // Discover
      re = new RegExp('^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)')
      if (this.payMedio.dataCards.number.match(re) != null) {
        type = 'Discover'
      }

      // Diners
      re = new RegExp('^36')
      if (this.payMedio.dataCards.number.match(re) != null) {
        type = 'Diners'
      }

      // Diners
      re = new RegExp('^3(?:0[0-59]{1}|[689])[0-9]{0,}')
      if (this.payMedio.dataCards.number.match(re) != null) {
        type = 'Diners'
      }

      // Diners - Carte Blanche
      re = new RegExp('^30[0-5]')
      if (this.payMedio.dataCards.number.match(re) != null) {
        // this.typeCard = 'diners-carte-blanche'
        // findType = true
      }

      // JCB
      re = new RegExp('^35(2[89]|[3-8][0-9])')
      if (this.payMedio.dataCards.number.match(re) != null) {
        type = 'JCB'
      }

      // JCB
      re = new RegExp('^(?:2131|1800|35)[0-9]{0,}$')
      if (this.payMedio.dataCards.number.match(re) != null) {
        type = 'JCB'
      }

      // JCB
      re = new RegExp('^30')
      if (this.payMedio.dataCards.number.match(re) != null) {
        type = 'JCB'
      }

      // Visa Electron
      re = new RegExp('^(4026|417500|4508|4844|491(3|7))')
      if (this.payMedio.dataCards.number.match(re) != null) {
        type = 'Visa Electron'
      }

      // Maestro
      re = new RegExp('^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)')
      if (this.payMedio.dataCards.number.match(re) != null) {
        type = 'Maestro'
      }

      // Maestro
      re = new RegExp('^(5[06789]|6)[0-9]{0,}')
      if (this.payMedio.dataCards.number.match(re) != null) {
        type = 'Maestro'
      }

      // Dankort
      re = new RegExp('^(5019)')
      if (this.payMedio.dataCards.number.match(re) != null) {
        type = 'Dankort'
      }

      // Interpayment
      re = new RegExp('^(636)')
      if (this.payMedio.dataCards.number.match(re) != null) {
        // this.typeCard = 'interpayment'
        // findType = true
      }

      // Unionpay
      re = new RegExp('^(62|88)')
      if (this.payMedio.dataCards.number.match(re) != null) {
        type = 'Unionpay'
      }

      return type
    },
  },
  created() {
    if (this.pos === 0 && this.payMedios.length === 1) {
      this.showRow = true
      this.$emit('setIndex', this.pos)
    } else if (this.payMedio.payments === 'banco') {
      if (!this.payMedio.name_banco) {
        this.showRow = true
        this.$emit('setIndex', this.pos)
      }
    } else if (this.payMedio.payments === 'zelle') {
      if (!this.payMedio.dataZelle.email && !this.payMedio.dataZelle.phone) {
        this.showRow = true
        this.$emit('setIndex', this.pos)
      }
    }

    /* if (this.payMedios.length === 1) {
      this.payMedio.principal = true
    } else */ if (
      this.payMedio.principal
    ) {
      this.showRow = true
      this.$emit('setIndex', this.pos)
    }

    if (this.payMedio.country && this.payMedio.principal) {
      this.setCountry()
    }

    if (!this.payMedio.is_new) {
      this.payMents.forEach(pMent => {
        let disabled = true
        if (pMent.slug === this.payMedio.payments) {
          disabled = false
        }
        this.payMentsLocal.push({
          ...pMent,
          disabled,
        })
      })
    } else {
      this.payMents.forEach(pMent => {
        this.payMentsLocal.push(pMent)
      })
    }

    if (this.payMedio.dataCards) {
      this.detectTypeCard()
    }
  },
  methods: {
    ...mapMutations(['deleteCtasBancarias']),
    setShowRow() {
      this.$nextTick(() => {
        this.showRow = !this.showRow
        this.$emit('setIndex', this.pos)
      })
    },
    showItem() {
      if (this.index === this.pos) {
        this.showRow = !this.showRow
      } else if (!this.showRow) {
        this.showRow = !this.showRow
      }
      this.$emit('setIndex', this.pos)
    },
    changePayMedio(method) {
      this.payMedio.payments = method.slug
    },
    setCountry() {
      if (this.payMedio.country && this.payMedio.principal) {
        const countr = this.countries.filter(e => e.name === this.payMedio.country)
        const json = { code: countr[0].code }

        this.axios
          .post('configuration/get-utc', json, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(response => {
            if (response.data.success === false) {
              // this.$toast.error(this.$t('msg.noAutorice'))
            } else {
              this.timeZones = response.data.data.data
            }
          })
          .catch(error => console.log(error))
      }
    },
    detectTypeCard() {
      let findType = false

      // visa
      let re = new RegExp('^4')
      if (this.payMedio.dataCards.number.match(re) != null) {
        this.typeCard = 'visa'
        findType = true
      }

      // Mastercard
      // Updated for Mastercard 2017 BINs expansion
      if (
        /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(
          this.payMedio.dataCards.number,
        )
      ) {
        this.typeCard = 'mastercard'
        findType = true
      }

      // American express
      re = new RegExp('^3[47]')
      if (this.payMedio.dataCards.number.match(re) != null) {
        this.typeCard = 'american-express'
        findType = true
      }

      // Discover
      re = new RegExp('^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)')
      if (this.payMedio.dataCards.number.match(re) != null) {
        this.typeCard = 'discover'
        findType = true
      }

      // Diners
      re = new RegExp('^36')
      if (this.payMedio.dataCards.number.match(re) != null) {
        this.typeCard = 'diners'
        findType = true
      }

      // Diners
      re = new RegExp('^3(?:0[0-59]{1}|[689])[0-9]{0,}')
      if (this.payMedio.dataCards.number.match(re) != null) {
        this.typeCard = 'diners'
        findType = true
      }

      // Diners - Carte Blanche
      re = new RegExp('^30[0-5]')
      if (this.payMedio.dataCards.number.match(re) != null) {
        // this.typeCard = 'diners-carte-blanche'
        // findType = true
      }

      // JCB
      re = new RegExp('^35(2[89]|[3-8][0-9])')
      if (this.payMedio.dataCards.number.match(re) != null) {
        this.typeCard = 'jcb'
        findType = true
      }

      // JCB
      re = new RegExp('^(?:2131|1800|35)[0-9]{0,}$')
      if (this.payMedio.dataCards.number.match(re) != null) {
        this.typeCard = 'jcb'
        findType = true
      }

      // JCB
      re = new RegExp('^30')
      if (this.payMedio.dataCards.number.match(re) != null) {
        this.typeCard = 'jcb'
        findType = true
      }

      // Visa Electron
      re = new RegExp('^(4026|417500|4508|4844|491(3|7))')
      if (this.payMedio.dataCards.number.match(re) != null) {
        this.typeCard = 'visa-electron'
        findType = true
      }

      // Maestro
      re = new RegExp('^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)')
      if (this.payMedio.dataCards.number.match(re) != null) {
        this.typeCard = 'maestro'
        findType = true
      }

      // Maestro
      re = new RegExp('^(5[06789]|6)[0-9]{0,}')
      if (this.payMedio.dataCards.number.match(re) != null) {
        this.typeCard = 'maestro'
        findType = true
      }

      // Dankort
      re = new RegExp('^(5019)')
      if (this.payMedio.dataCards.number.match(re) != null) {
        this.typeCard = 'dankort'
        findType = true
      }

      // Interpayment
      re = new RegExp('^(636)')
      if (this.payMedio.dataCards.number.match(re) != null) {
        // this.typeCard = 'interpayment'
        // findType = true
      }

      // Unionpay
      re = new RegExp('^(62|88)')
      if (this.payMedio.dataCards.number.match(re) != null) {
        this.typeCard = 'unionpay'
        findType = true
      }

      if (!findType) {
        this.typeCard = null
      }
    },
  },
}
</script>
<style scoped>
::v-deep .my-input .v-label,
::v-deep .my-input input {
  font-size: 12px !important;
  line-height: 1;
  min-height: 8px;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
::v-deep .v-text-field__details {
  padding: 0 0 0 0;
}
</style>
